.root {
  margin-bottom: 2em;
}

.subtitle1 {
  font-weight: bold;
}

.fontSizeSmall {
  margin-left: 3px;
}
