.form {
  /* 置中 */
  margin: auto;
  /* 最大寬度 */
  max-width: 20rem;
  height: 100vh;
}

.TextField {
  text-align: center;
}

/* flex container height 只影響下面一層，所以直接設定在 img 上 */
/* 如果沒有設定在 img 的 Grid 上 height: 100% 的話，會把 Grid 撐大 */
.bulletinImg {
  display: flex;
  object-fit: cover;
  max-width: calc(100vw - 30rem);
  height: 100vh;
}

.logoImg {
  display: flex;
  max-width: 100%;
  max-height: 50vh;
  margin: auto;
}
